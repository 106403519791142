
import ScrollToTop from 'Components/Layout/ScrollToTop';
import MainNavigation from 'Navigation/Navigation';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import 'assets/base.scss';
import CookieConsent from 'Components/Cookies/CookieConsent';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <MainNavigation />
        <CookieConsent />
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
