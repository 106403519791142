import React, { lazy, Suspense } from 'react';

import { ThemeProvider } from '@material-ui/styles';
import MuiTheme from 'Theme';
import { Route, Switch, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import SuspenseLoading from 'Components/Layout/SuspenseLoading';

const Home = lazy(() => import('Routes/Home'))

const pageVariants = {
  initial: { opacity: 0 },
  in: { opacity: 1 },
  out: { opacity: 0 }
};

const pageTransition = {
  type: 'tween',
  ease: 'linear',
  duration: 0.3
};

const MainNavigation = () => {
  const location = useLocation();

  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            <Route path={['/']}>
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route path="/" component={Home} />
                </motion.div>
              </Switch>
            </Route>
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  )
}

export default MainNavigation
