import React from 'react'




const CookieDocument = () => {
  return (
    <>
      <h4>POLITIQUE DE COOKIES</h4>

      <h4>1. L'utilisation de cookies</h4>

      <p>Notre site internet <em>E1OS.com</em> utilise des cookies. Un cookie est un petit fichier qui est envoyé avec les pages de ce site Web et/ou les applications Flash et qui est stocké par votre navigateur sur votre disque dur à partir de votre ordinateur, téléphone portable, montre connectée ou tablette. Les informations qui y sont stockées peuvent être retournées à nos serveurs lors d'une visite ultérieure.</p>

      <p>L'utilisation de cookies est d'une grande importance pour le bon fonctionnement de notre site web. Grâce à la contribution (anonyme) des visiteurs, nous pouvons améliorer l'utilisation du site internet et le rendre plus convivial.</p>

      <h4>2. Consentement </h4>

      <p>Votre consentement est requis pour l'utilisation de certains cookies. Nous le recueillons au moyen d'une bannière informative.</p>

      <h4>3. Le type de cookies utilisés et leurs objectifs</h4>

      <p>Nous utilisons les cookies suivants :</p>

      <ul>
        <li>Cookies fonctionnels : ils nous permettent d'améliorer le fonctionnement du site internet et de le rendre plus convivial pour le visiteur. Par exemple, nous stockons vos données de connexion.</li>
      </ul>

      <h4>3 bis. Liste des cookies utilisés</h4>

      <h5>Cookies Google :</h5>

      <ul>
        <li>Google analytics : permet de mesurer l'audience du site.</li>
        <li>Google tag manager : facilite l’implémentation des tags sur les pages et permet de gérer les balises Google.</li>
        <li>Google Adsense : régie publicitaire de Google utilisant les sites web ou les vidéos YouTube comme support pour ses annonces.</li>
        <li>Google Dynamic Remarketing : permet de vous proposer de la publicité dynamique en fonction des précédentes recherches.</li>
        <li>Google Adwords Conversion : outil de suivi des campagnes publicitaires adwords.</li>
        <li>DoubleClick : cookies publicitaires de Google pour diffuser des bannières.</li>
      </ul>

      <h4>4. Vos droits à l'égard de vos données personnelles</h4>

      <p>Vous disposez d'un droit d'accès, de rectification, de limitation et de suppression de vos données personnelles. En outre, vous avez le droit de vous opposer au traitement des données personnelles et le droit à la transférabilité de vos données. Vous pouvez exercer ces droits en nous envoyant un mail à l'adresse suivante <span class="boiler-field">contact@e1os.com</span>. Afin de prévenir les abus, nous pouvons vous demander de vous identifier sur notre site. Lorsqu'il s'agit d'accéder à des données personnelles liées à un cookie, nous vous demandons de nous envoyer une copie du cookie en question. Vous pouvez les trouver dans les paramètres de votre navigateur.</p>

      <h4>5. Blocage et suppression des cookies</h4>

      <p>Vous pouvez facilement bloquer et supprimer vous-même les cookies à tout moment par votre navigateur Internet. Vous pouvez également configurer votre navigateur Internet de manière à recevoir un message lorsqu'un cookie est installé. Vous pouvez également indiquer que certains cookies ne peuvent pas être installés. Pour cela, veuillez consultez la fonction d'aide de votre navigateur. Si vous supprimez les cookies de votre navigateur, cela peut le cas échéant avoir des conséquences sur l'utilisation de notre site internet.</p>

      <p>Certains cookies de suivi sont installés par des tiers qui vous affichent des publicités via notre site Web. Vous pouvez supprimer ces cookies de manière centralisée via www.youronlinechoices.eu.</p>

      <p>Veuillez noter que si vous ne voulez pas de cookies, nous ne serons plus en mesure de garantir le bon fonctionnement de notre site Web. Certaines caractéristiques du site peuvent être altérées et dans certains cas, vous ne pourrez plus accéder au site. De plus, le refus des cookies ne signifie pas que vous ne verrez aucune publicité. Les annonces ne sont plus adaptées à vos centres d'intérêt et peuvent donc apparaître  plus souvent.</p>

      <p>Les étapes à suivre pour ajuster vos paramètres varient d'un navigateur à un autre. Si nécessaire, consultez la fonction d'aide de votre navigateur, ou rendez-vous sur l’un des liens ci-dessous pour accéder directement au manuel de votre navigateur.</p>

      <p>Firefox : https://support.mozilla.org/fr/kb/effacer-les-cookies-pour-supprimer-les-information</p>

      <p>Google Chrome : https://support.google.com/chrome/answer/95647?co=GENIE.Platform=Desktop&amp;hl=fr</p>

      <p>Internet Explorer : https://support.microsoft.com/fr-fr/help/278835/how-to-delete-cookie-files-in-internet-explorer</p>

      <p>Safari : https://support.apple.com/kb/ph21411?locale=fr_FR</p>

      <h4>6. Nouveaux développements et cookies non prévus</h4>

      <p>Sur certaines de nos pages, nous pouvons utiliser du contenu qui est hébergé sur d'autres sites et qui est rendu accessible sur notre site internet au moyen de certains codes (contenu intégré).  Ces codes utilisent souvent des cookies. Nous n’avons cependant aucun contrôle sur ce que ces tiers font de leurs cookies.</p>

      <p>Il est également possible que, par l'intermédiaire de notre site internet, des cookies soient placés par d'autres personnes. Vous avez trouvé sur notre site internet des cookies que nous n'avons pas identifiés ? Veuillez nous le faire savoir par mail. Vous pouvez également contacter directement le tiers et lui demander quels cookies il place, quelle en est la raison, quelle est la durée de vie du cookie et quelles sont les mesures pour protéger votre vie privée. </p>

      <h4>7. Remarques</h4>

      <p>Nous devrons mettre à jour cette politique d'utilisation des cookies régulièrement par exemple lorsque nous modifions notre site Web ou les règles le concernant. Nous vous prions de consulter cette page pour prendre connaissance de la dernière version de notre politique d'utilisation des cookies.</p>

      <p>Si vous avez des questions et/ou des commentaires, veuillez nous contacter à l'adresse e-mail suivante : <span class="boiler-field">contact@e1os.com</span></p>
    </>
  )
}

export default CookieDocument