import { Dialog, DialogContentText } from '@material-ui/core'
import React from 'react'
import CookieDocument from './CookieDocument'

const CookieModal = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll='body'
      maxWidth='lg'
    >
      <DialogContentText className='p-5'>
        <CookieDocument />
      </DialogContentText>
    </Dialog>
  )
}

export default CookieModal