import React, { useState } from 'react'

import ReactCookieConsent from "react-cookie-consent";
import { Button } from '@material-ui/core';
import CookieModal from './CookieModal';

const CookieConsent = () => {
  const [open, setOpen] = useState(false)
  return (
    <ReactCookieConsent
      buttonText="Accepter l'utilisation des cookies"
      onAccept={() => window.location.reload()}
    >
      Nous utilisons des cookies pour assurer le bon fonctionnement du site.
      <Button
        color='primary'
        onClick={() => setOpen(true)}
      >
        Voir notre politique de cookies
      </Button>
      <CookieModal open={open} onClose={() => setOpen(false)} />
    </ReactCookieConsent>
  )
}

export default CookieConsent